import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('../views/Dashboard'),
		},
		{
			path: '/member-list',
			name: 'member-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/member/accountList'),
		},
		{
			path: '/banner-list',
			name: 'banner-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/banner/bannerList'),
		},
		{
			path: '/banner-create',
			name: 'banner-create',
			component: () => import('../views/banner/bannerCreate'),
		},
		{
			path: '/banner-edit',
			name: 'banner-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/banner/bannerEdit'),
		},
		{
			path: '/icon-list',
			name: 'icon-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/icon/iconList'),
		},
		{
			path: '/icon-create',
			name: 'icon-create',
			component: () => import('../views/icon/iconCreate'),
		},
		{
			path: '/icon-edit',
			name: 'icon-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/icon/iconEdit'),
		},
		{
			path: '/client-list',
			name: 'client-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/client/clientList'),
		},
		{
			path: '/client-create',
			name: 'client-create',
			component: () => import('../views/client/clientCreate'),
		},
		{
			path: '/client-edit',
			name: 'client-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/client/clientEdit'),
		},
		{
			path: '/category-list',
			name: 'category-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/category/categoryList'),
		},
		{
			path: '/category-create',
			name: 'category-create',
			component: () => import('../views/category/categoryCreate'),
		},
		{
			path: '/category-edit',
			name: 'category-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/category/categoryEdit'),
		},
		{
			path: '/product-list',
			name: 'product-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productList'),
		},
		{
			path: '/product-create',
			name: 'product-create',
			component: () => import('../views/product/productCreate'),
		},
		{
			path: '/product-edit',
			name: 'product-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productEdit'),
		},
		{
			path: '/sku-list',
			name: 'sku-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/skuList'),
		},
		{
			path: '/order-list',
			name: 'order-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				shopId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderList'),
		},
		{
			path: '/sale-order-detail',
			name: 'sale-order-detail',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				salonId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderDetail'),
		},
		{
			path: '/sell-hospitality',
			name: 'sell-hospitality',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '' 
			  },
			component: () => import('../views/services/sellHospitality'),
		},
		{
			path: '/buy-hospitality-list',
			name: 'buy-hospitality-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/services/buyHospitalityList'),
		},
		{
			path: '/buy-hospitality-create',
			name: 'buy-hospitality-create',
			component: () => import('../views/services/buyHospitalityCreate'),
		},
		{
			path: '/buy-hospitality-edit',
			name: 'buy-hospitality-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/services/buyHospitalityEdit'),
		},
		{
			path: '/service-list',
			name: 'service-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/services/serviceList'),
		},
		{
			path: '/service-create',
			name: 'service-create',
			component: () => import('../views/services/serviceCreate'),
		},
		{
			path: '/service-edit',
			name: 'service-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/services/serviceEdit'),
		},
		{
			path: '/about-us',
			name: 'about-us',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/setting/aboutUs'),
		},
		{
			path: '/contact-info',
			name: 'contact-info',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/setting/contactInfo'),
		},
		{
			path: '/browse-info',
			name: 'browse-info',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/setting/browseInfo'),
		},
		{
			path: '/seller-list',
			name: 'seller-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/seller/sellerList'),
		},
		{
			path: '/seller-create',
			name: 'seller-create',
			component: () => import('../views/seller/sellerCreate'),
		},
		{
			path: '/seller-edit',
			name: 'seller-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/seller/sellerEdit'),
		},
		{
			path: '/call-now-list',
			name: 'call-now-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/setting/callNowList'),
		},
		{
			path: '/call-now-create',
			name: 'call-now-create',
			component: () => import('../views/setting/callNowCreate'),
		},
		{
			path: '/call-now-edit',
			name: 'call-now-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/setting/callNowEdit'),
		},
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router